import React from "react"
import { GatsbyLocation } from "local-types"
import SEO from "../components/Seo/Seo"
import styled from "styled-components"
import { device } from "../styles/constants"

import SectionLayout from "../components/WrapperSections/SectionLayout"

import { useSiteMetadata, usePageTransitions } from "../hooks"

interface TermsOfUseProps {
  location: GatsbyLocation
  preivousPath?: string
  nextPath?: string
}

const standartMetadata = {
  Title: "Flipbase",
  Keywords: ["Flipbase"],
  Description:
    "We are a software company with the mentality of a pioneer, always trying to be ahead of the market and constantly searching for and developing new technology. Read more about Flipbase.",
  Image: "/seo-image-general.png",
}

const TermsOfUse: React.FunctionComponent<TermsOfUseProps> = ({
  location,
}): React.ReactElement => {
  const { title: siteTitle } = useSiteMetadata()

  return (
    <>
      <SEO
        title={standartMetadata.Title}
        description={standartMetadata.Description}
        keywords={standartMetadata.Keywords}
        image={standartMetadata.Image}
      />
      <SectionLayout>
        <WrapperContent>
          <h3>TERMS OF USE FLIPBASE</h3>

          <h5>1. Definitions</h5>
          <p>
            1.1. In these terms of use, the following terms, indicated with a
            capital, whether single or plural, will have the following meaning:
          </p>
          <p>
            <i>Agreement:</i> the agreement between Flipbase and Customer
            regarding the access to and use of the Service;
          </p>
          <p>
            <i>Candidate Screening:</i> the video recording and playback
            functionality of Flipbase integrated into the recruitment process
            and system of Customer;
          </p>
          <p>
            <i>Content:</i> Flipbase Content and User Content;
          </p>
          <p>
            <i>Dashboard: </i>the graphical user interface with which Customer
            can use the Employer Branding module;
          </p>
          <p>
            <i>Employer Branding:</i> a module of Flipbase with which Customer
            can produce, manage and publish video content of Users through the
            Dashboard;
          </p>
          <p>
            <i>Flipbase:</i> the general partnership Flipbase, established and
            existing under the laws of the Netherlands, having its registered
            office in Amsterdam, registered with the Chamber of Commerce of the
            Netherlands under number 59906960;
          </p>
          <p>
            <i>Flipbase Content:</i> all information, data or material in the
            form of images, videos, text and audio-visual material or any other
            content made available by Flipbase and/or its licensor(s) through
            the Service whether or not on a customized basis;
          </p>
          <p>
            <i>Intellectual Property Rights:</i> all intellectual property
            rights and associated rights, including copyrights, trade mark
            rights, patent rights, design rights, trade name rights, database
            rights, and neighbouring rights, as well as rights to knowhow;
          </p>
          <p>
            <i>Service:</i> the access to and use of the Application Process
            and/or Employer Branding, or other services Flipbase provides
            Customer (and its Users), as set out in the Agreement;
          </p>
          <p>
            <i>Terms of Use:</i> the underlying terms and conditions of Flipbase
            pertaining to the use of the Service, which form an integral part of
            the Agreement;
          </p>
          <p>
            <i>User:</i> a natural or legal person who uses the Service, such as
            Customer’s employees and/or candidates;
          </p>
          <p>
            <i>User Content:</i> all information, data or material in the form
            of images, videos, text and audio-visual material or any other
            content submitted by a User through the Service.
          </p>
          <h5>2. General</h5>
          <p>
            2.1. These Terms of Use apply to all Agreements between Customer and
            Flipbase and every use made of the Service by Customer and/or User.
            The applicability of any general -, purchase -, delivery - and/or
            other conditions of Customer are explicitly and expressly excluded
            and shall thus not apply, unless set out differently by Flipbase
            expressly and in writing.
          </p>
          <p>
            2.2. If at any time any provision of these Terms of Use is or
            becomes illegal, void or invalid for any reason whatsoever, such
            invalidity shall not affect the validity of the remainder of these
            Terms of Use and such invalid provision shall be replaced by another
            provision which, being valid in all respects, shall have an effect
            as close as possible to that of the replaced provision.
          </p>
          <h5>3. Service</h5>
          <p>
            3.1. Flipbase shall grant Customer access to the Service and shall
            use reasonable commercial endeavours to keep the Service operational
            in accordance with the Agreement.
          </p>
          <p>
            3.2. If Customer complies in full with all obligations pursuant to
            this Agreement, Flipbase will grant Customer and Users a limited,
            personal, revocable, non-exclusive, non-sublicensable and
            non-transferable right to use the Service and the Flipbase Content.
          </p>
          <p>
            3.3. Each and every use of the Service is at Customer’s own risk and
            responsibility. Flipbase has no knowledge of and/or interference
            with the User Content that is made available by Customer and/or User
            through use of the Service and/or the Dashboard. Customer is solely
            responsible and liable for User Content Customer and/or User
            provides. Flipbase accepts no liability/responsibility whatsoever
            for any decisions made by Customer based on the Content in the
            Service. Customer acknowledges and agrees that Flipbase does not
            have any influence over the User Content uploaded/processed by means
            of the Service.
          </p>
          <p>
            3.4. Flipbase is at all times, without in any way becoming liable to
            Customer, entitled:
          </p>
          <p>
            a. to make procedural and technical alterations and/or improvements
            to the Service; and
          </p>
          <p>
            b. to temporarily discontinue or limit the Service if, in its view,
            this is necessary, for example for purposes of preventive,
            corrective or adaptive maintenance. Flipbase will notify Customer of
            the temporary unavailability or restricted use of the Service
            insofar and as soon as reasonably possible.
          </p>
          <p>
            3.5. For the use of third party services Customer and User are bound
            by the conditions for use of these services. Flipbase has no control
            over, and assumes no responsibility for the data, content,
            information, privacy policies, terms and conditions, and/or
            practices of any of these third party services.
          </p>
          <h5>4. Intellectual Property Rights</h5>
          <p>
            4.1. The Intellectual Property Rights relating to the Service,
            including Flipbase Content and the Dashboard, are owned by Flipbase
            and/or its licensors. Nothing in the Agreement constitutes the
            transfer of any Intellectual Property Rights from Flipbase to
            Customer. Customer solely granted a license as described in Article
            3.2.
          </p>
          <p>
            4.2. Customer and User retain the Intellectual Property Rights
            relating to the User Content they make available through the
            Service. For the avoidance of doubt, this article 4 will survive
            termination of the Agreement.
          </p>
          <p>
            4.3. Customer represents and warrants that it has all rights to
            grant the licenses as laid down in article 4.2 of these Terms of
            Use, without infringing or violating any third party rights,
            including but limited to, any privacy rights, publicity rights,
            Intellectual Property Rights or any other proprietary rights.
            Customer indemnifies Flipbase against any and all third party
            claims, based upon any alleged infringement of such third party
            rights in relation to the User Content.
          </p>
          <p>
            4.4. Flipbase does not claim any ownership rights of the User
            Content, has no obligation to review the User Content and can in no
            way be held responsible for the User Content uploaded through the
            Service.
          </p>
          <h5>5. Privacy</h5>
          <p>
            5.1. In case Flipbase has access to personal data when performing
            the Agreement, and performing the Agreement requires Flipbase to
            process personal data, Flipbase will solely be acting as processor
            (“verwerker”) within the meaning of the Dutch Personal Data
            Protection Act General Data Protection Regulation (“GDPR”). In that
            case, Flipbase and Customer will conclude a data processing
            agreement within the meaning of article 28(3) GDPR.
          </p>
          <p>
            5.2. Customer will ensure that the use of the Service is compliant
            with all applicable data privacy and data security laws and
            regulations within Customer’s region and that it is consistent with
            generally accepted and commercial reasonable industry standards.
          </p>
          <p>
            5.3. Customer agrees to indemnify, defend and hold harmless Flipbase
            from any damages and losses resulting from third party claims
            arising from Customer’s non-compliance with its obligations under
            the data privacy and security laws, Customer will adjust or initiate
            that locally delivered services comply with local privacy laws and
            regulations.
          </p>
          <p>
            5.4. Customer acknowledges and agrees that the User Content, whether
            or not a User submits its User Content to Customer, is retained for
            a period of 24 hours on the servers of Flipbase, after which it is
            automatically deleted. The processing of this User Content is also
            covered by the data processing agreement mentioned in article 5.1.
            Customer will ensure that all applicable data privacy and data
            security laws and regulations are complied with in this context,
            including but not limited to necessary information obligations.
          </p>
          <h5>6. Fees and payment</h5>
          <p>
            6.1. Customer agrees to pay Flipbase the fees as specified in the
            Agreement. All fees quoted and payable are in euro’s and exclusive
            of turnover tax (VAT) and other government levies/taxes. Payments by
            Customer need to be done no later than 30 days after invoice date.
          </p>
          <p>
            6.2. After the initial term of the Agreement, Flipbase may increase
            the fees.
          </p>
          <p>
            6.3. If Flipbase has carried out work or performed other services
            that fall outside of the content or scope of the agreed work and/or
            services at request or with the prior consent of Customer, such work
            or services shall be paid for by Client in accordance with the
            agreed rates. If no rates have been agreed, Flipbase’s standard
            rates shall apply. Flipbase shall under no circumstances be obliged
            to comply with such a request, and where it does comply, it may
            require Customer to enter into separate written agreement for this
            purpose.
          </p>
          <p>
            6.4. Complaints regarding the Service and/or Support under this
            Agreement and/or the invoice do not suspend the payment obligation
            of Customer.
          </p>
          <p>
            6.5. In case amounts under this Agreement are undisputed by Customer
            and not received by Flipbase within the period as set forth in
            article 6.1 of this Agreement, Flipbase reserves the right, without
            prejudice to any other rights and remedies and upon giving notice to
            Customer and a remedy period of five (5) business days, to: (i)
            suspend Services, including, without limitation, the supply of
            pending and future orders, and (ii) to charge legal interest on a
            day-to-day basis from the original due date.
          </p>
          <h5>7. Liability</h5>
          <p>
            7.1. Flipbase's total, aggregate, cumulative liability for
            attributably failing (“toerekenbare tekortkoming”) to correctly
            perform the Agreement (which includes a breach of any warranty), any
            unlawful act (“onrechtmatige daad”) will be limited to direct
            damages only and shall never exceed the total amounts paid for the
            services in the twelve (12) preceding months of that particular
            calendar year.
          </p>
          <p>7.2 Direct damages shall solely mean:</p>
          <p>a. property damages (“zaakschade”);</p>
          <p>
            b. reasonable expenses which Customer would have to incur to make
            Flipbase's performance conform to the Agreement; this alternative
            damage shall not be compensated, however, if the Agreement is
            rescinded by or at the suit of Customer;
          </p>
          <p>
            c. reasonable expenses incurred to determine the cause and scope of
            the damage, insofar as the determination relates to direct damage
            within the meaning of this Agreement;
          </p>
          <p>
            d. reasonable expenses incurred to prevent or mitigate damage,
            insofar as Customer demonstrates that these expenses resulted in
            mitigation of direct damage within the meaning of this Agreement.
          </p>
          <p>
            7.3. Flipbase’s liability for damages other than direct damages,
            arising out of, or in connection with the Agreement, such as, but
            not limited to indirect loss, consequential loss, loss of, loss of
            use and/or damage of any data, Content or software, loss of profit
            and loss of revenue, loss of business, loss of anticipated savings,
            or any other similar financial loss or loss of goodwill or
            reputation, damage as a result of claims from third parties or other
            incidental, indirect, punitive or exemplary damages of any kind, is
            excluded.
          </p>
          <p>
            7.4. The limitations mentioned in the preceding paragraphs of this
            article 7 shall not apply if and insofar as the damage or injury is
            the result of intentional acts or omissions or wilful negligence by
            Flipbase or its managers (“own actions”).
          </p>
          <p>
            7.5. No right to damages shall exist unless Customer notifies
            Flipbase in writing of the damage promptly after it has arisen. Any
            claim for damages against Flipbase shall become extinguished by the
            mere lapse of six (6) month after the claim has come into being.
          </p>
          <h5>8. Force majeure</h5>
          <p>
            8.1. In the event of force majeure, there will be no attributable
            failure in the performance of the Agreement by Flipbase.
          </p>
          <p>
            8.2. Force majeure includes, among other things, employees on sick
            leave and/or absence of employees who are crucial to the supply of
            the services, interruptions in the supply of electricity, strikes,
            riots, government measures, fire, natural disasters, floods, failure
            on the part of Flipbase’s suppliers, failure on the part of third
            parties engaged by Flipbase, interruptions in connection to the
            internet (whether or not due to a DDoS attack), hardware
            malfunctions, malfunctions in networks, including telecommunication
            networks, and other unforeseen circumstances.
          </p>
          <p>
            8.3. If the force majeure continues for at least thirty (30) days,
            Flipbase is entitled to terminate the Agreement without being
            obliged to pay any compensation for this termination.
          </p>
          <h5>9. Warranties and indemnities</h5>
          <p>
            9.1. As far as possible in the light of mandatory statutory
            provisions, Flipbase expressly disclaims any and all explicit and
            implied guarantees, undertakings and warranties of any nature,
            including but not limited to guarantees, undertakings and warranties
            with respect to the quality, security, lawfulness, integrity and
            accuracy of the Service.
          </p>
          <p>
            9.2. Flipbase does not warrant that the Service will be accessible
            at all times and without interruptions or failures. Failures of the
            Service may be caused in part, but not exclusively, by failures in
            the internet or telephone connection or by viruses or
            errors/defects. Flipbase will not be liable to Customer in any way
            for damage ensuing from or caused by the unavailability of the
            Service.
          </p>
          <p>
            9.3. Flipbase does not guarantee that the Service shall be
            effective, the use of the Service shall lead to certain results or
            that the Content provided through the Service shall be accurate, up
            to date and complete.
          </p>
          <p>9.4. Flipbase is not responsible for:</p>
          <p>
            a. the purchase and/or the proper operation of Customer’s
            infrastructure;
          </p>
          <p>
            b. loss, damage, inaccuracy and/or incompleteness of any Content
            provided through the Service;
          </p>
          <p>
            c. transmission errors, malfunctions or non-availability of
            computer, Content, data or telecom facilities, including the
            internet;
          </p>
          <p>d. any unauthorized use or attempted use of the Service;</p>
          <p>e. making backup copies of any Content; or</p>
          <p>
            f. the management, including checking the settings, the use of the
            Service and the manner in which the Content is used.
          </p>
          <p>
            9.5. Flipbase shall never be liable for any of the aforementioned
            acts or circumstances. Customer indemnifies Flipbase against all
            damage and costs, including – but not limited to – damage resulting
            from (alleged) infringements of Intellectual Property Rights, claims
            by third parties, including Users, collection costs, the statutory
            commercial interest, loss of profits, penalties incurred and legal
            fees, which Flipbase incurs or which result from a breach of the
            Agreement by Customer or an unlawful act.
          </p>
          <h5>10. Term and termination</h5>
          <p>
            10.1. This Agreement shall enter into force upon signing by both
            Parties and shall remain in force for the term as specified in the
            Agreement. In case the Agreement remains in force for an indefinite
            period of time, either Party may terminate the agreement by
            providing the other Party written notice of termination of the
            Agreement, taking into account a notice period of no less than two
            (2) months. In case the Agreement remains in force for a definite
            period of time, the Agreement shall be automatically extended for
            the same term on the same terms and conditions, unless terminated by
            either Party by providing the other Party written notice of
            termination of the Agreement, taking into account a notice period of
            no less than three (2) months.
          </p>
          <p>
            10.2. Flipbase may immediately terminate the Agreement, without
            becoming liable, upon written notification to Customer in case:
          </p>
          <p>
            a. Customer applies for a moratorium of payments, is declared
            bankrupt or otherwise ceases to meet its (financial) obligations;
          </p>
          <p>
            b. Customer suspends or discontinues its business activities; or
          </p>
          <p>
            c. of any change of control in Customer, its affiliates and/or
            shareholders.
          </p>
          <p>
            10.3. Flipbase may immediately (temporarily or permanently) suspend
            its service at any time, and without notice to Customer if Customer
            breaches this Agreement or otherwise engages in (unlawful)
            activities that are likely to cause liability to Flipbase. Flipbase
            will not be liable for any costs, expenses, or damages as a result
            of its termination of the Agreement.
          </p>
          <p>
            10.4. Upon termination of the Agreement, for whatever reason, any
            and all rights granted to Customer under this Agreement will
            automatically terminate, and Customer will immediately cease any and
            all use of the Service, Flipbase Content, Intellectual Property
            Rights and the Dashboard.
          </p>
          <p>
            10.5. In the event of termination, any performance already delivered
            and the payment obligations related to it will not be the subject of
            reversal, unless Flipbase is in default in relation to such
            performance. Amounts invoiced by Flipbase before the termination in
            connection with anything that it has already performed or delivered
            in implementing the Agreement will continue to be owed and will
            become immediately due and payable at the time of the termination.
          </p>
          <p>
            10.6. Provisions that by their nature are destined to survive
            termination of the Agreement will remain in full force after
            termination.
          </p>
          <h5>11. Miscellaneous</h5>
          <p>
            11.1. Flipbase may sell, lease, assign, or otherwise transfer in
            whole or in part the Agreement or any rights or obligations granted
            hereunder without the advance consent of Customer.
          </p>
          <p>
            11.2. The Agreement shall be governed by the laws of the
            Netherlands.
          </p>
          <p>
            11.3. All controversies, disputes or claims arising out of or
            relating to this Agreement or the breach thereof shall be
            exclusively and finally settled by the competent civil court in
            Amsterdam.
          </p>
          <DownloadButton href="/Terms-of-Use-Flipbase-1.pdf" target="_blank">
            DOWNLOAD .PDF
          </DownloadButton>
        </WrapperContent>
      </SectionLayout>
    </>
  )
}

const WrapperContent = styled.div`
  font-family: FreightSans;
  color: #221e30;
  @media ${device.tablet} {
    margin: 0 32px;
  }
  @media ${device.mobileL} {
    margin: 0 16px;
  }
  table {
    margin: 64px 0;
  }
  h3 {
    font-size: 28px;
    font-weight: 600;
    margin: 24px 0;
    line-height: 1.75;
  }
  h4 {
    font-size: 24px;
    font-weight: 600;
    margin: 16px 0;
    line-height: 1.75;
  }
  h5 {
    font-size: 22px;
    font-weight: 600;
    margin: 16px 0;
    line-height: 1.75;
  }
  p,
  li,
  td {
    font-size: 20px;
    margin: 8px 0;
    line-height: 1.75;
  }
  td {
    font-weight: 800;
    padding: 24px 16px;
  }
  a {
    color: #a62651;
    :hover {
      color: #a62651;
    }
  }
  margin-bottom: 64px;
`

const DownloadButton = styled.a`
  width: 100%;
  background-color: #221e30;
  color: #ffffff !important;
  display: block;
  text-shadow: none;
  text-align: center;
  font-size: 20px;
  padding: 16px 0;
  cursor: pointer !important;
  margin: 32px 0;
`

export default TermsOfUse
